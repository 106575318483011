import React from "react";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { useTranslation } from "gatsby-plugin-react-i18next";

const Privacy = () => {
  const { t } = useTranslation();
  return (
    <section className="services-details-area">
      <div className="container">
        <div className="section-title">
          <span className="sub-title">
            <StaticImage
              src="../../assets/images/sun-icon.jpg"
              alt="about"
              style={{ verticalAlign: "middle", marginRight: "5px" }}
              width={32}
              height={32}
            />
            {t("Imprint")}
          </span>
        </div>
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="services-details-desc">
              <p>Blockstrom AG<br />Morillonstrasse 77<br />3007 Bern</p>
              <p>{t("Company - Imprint - Text - 1")}<br />{t("Company - Imprint - Text - 2")}</p>
              <p>{t("Company - Imprint - Text - 3")}<br />{t("Company - Imprint - Text - 4")}</p>
              <p>{t("Company - Imprint - Text - 5")}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Privacy;